/** @jsx jsx */

// Components
import { Link, graphql } from "gatsby";
import { jsx, Container, Box, Heading } from "theme-ui";

import { PostList } from "../components";
import Layout from "../components/global/Layout";

const TagTemplate = ({ data, pageContext }) => {
	const { tag } = pageContext;

	const { allMarkdownRemark } = data;

	return (
		<Layout>
			<Box as="section">
				<Container px={[3, 4]}>
					{/* <TagsMenu my={[2, 3]} /> */}
					<Box mt={[3, 4]} mb={[2, 3]}>
						<Link to="/posts/">View all articles</Link>
					</Box>
					<Box py={(3, 4)}>
						<Heading as="h1" mb={[2, 3]}>
							{tag}
						</Heading>
					</Box>

					<PostList posts={allMarkdownRemark.edges} isTag />
				</Container>
			</Box>
		</Layout>
	);
};

export default TagTemplate;

export const query = graphql`
	query PagesByTag($tag: [String]!) {
		allMarkdownRemark(
			filter: { frontmatter: { tags: { in: $tag }, draft: { eq: false } } }
		) {
			edges {
				node {
					frontmatter {
						title
						image
						tags
					}
					fields {
						slug
					}
				}
			}
			totalCount
		}
	}
`;
